<template>
  <div class="organize-tab">
    <div class="custom-card">
      <div>
        <b-alert variant="primary" show>
          <span class="custom-text">
            <b>Grouping is optional.</b> If you would like to create groups for your featured endorsements, you simply need to click "Add New Group". Give your group a name and click "Save". You can now click and drag any of your featured endorsements into this group. Repete this process for additional groups.
          </span>
        </b-alert>

        <div>
          <b-button size="lg" @click="isEditing = true">ADD NEW GROUP</b-button>
        </div>
        <div v-if="isEditing" class="mt-3 mb-4">
          <b-form inline>
            <b-form-input
              v-model="newGroup"
              id="inline-form-input-name"
              class="mb-2 mr-sm-2 mb-sm-0"
              size="lg"
              placeholder="Group name"
            ></b-form-input>

            <b-button variant="success" size="lg" @click="addGroup"
              >SAVE</b-button
            >
          </b-form>
        </div>
      </div>

      <draggable v-model="groups" @end="updateGroupOrder" tag="div">
        <div
          v-for="(group, idx) in groups"
          :key="'grp' + group.id"
          class="sortable"
        >
          <div class="mt-4 mb-3">
            <b-icon icon="list"></b-icon>
            <span class="h4 text-uppercase ml-4 mr-4"
              ><strong>{{ group.title }}</strong></span
            >
            <a v-if="editingIndex == -1" href="#/" @click="editGroup(idx)"
              >Edit name</a
            ><span class="text-muted mx-2">|</span>
            <a v-if="editingIndex == -1" href="#/" @click="deleteGroup(idx)"
              >Delete Group</a
            >
            <b-icon
              :icon="group.visible ? `chevron-down` : 'chevron-up'"
              class="mb-1 custom-icon float-right"
              @click="group.visible = !group.visible"
            ></b-icon>
          </div>
          <b-collapse v-model="group.visible">
            <draggable
              :id="group.title"
              :list="
                listGroups[listGroups.findIndex(x => x.groupId == group.id)]
                  .members
              "
              class="list-group"
              draggable=".item"
              group="a"
              @change="changeOrder"
            >
              <div
                class="list-group-item item"
                v-for="element in listGroups[
                  listGroups.findIndex(x => x.groupId == group.id)
                ].members"
                :key="element.endorsementId"
              >
                <b-row>
                  <b-col cols="1">
                    <b-icon icon="arrows-move" aria-hidden="true" class="text-muted"></b-icon>
                  </b-col>
                  <b-col cols="4">
                    {{
                    `${element.firstName} ${element.lastName}`
                    }}
                  </b-col>
                  <b-col>
                    <span class="key-color key-orange"
                          v-if="colorCheck(1, element.position)"></span>
                    <span class="key-color key-red"
                          v-if="colorCheck(2, element.position)"></span>
                    <span class="key-color key-green"
                          v-if="colorCheck(3, element.position)"></span>
                    <span class="key-color key-yellow"
                          v-if="colorCheck(4, element.position)"></span>
                    <span class="key-color key-purple"
                          v-if="colorCheck(5, element.position)"></span>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col>
                        <span :class="
                            `featured-label float-right text-right text-${
                              element.featured ? 'black' : 'secondary'
                            }`
                          ">
                          Featured
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <b-form-checkbox switch
                                         size="lg"
                                         class="float-right"
                                         v-model="element.featured"></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </draggable>
          </b-collapse>
        </div>
      </draggable>
      <div class="mb-4">
        <div class="mt-4 mb-3">
          <span class="h4 text-uppercase"
            ><strong>Not assigned to group</strong></span
          >
          <b-icon
            :icon="show ? `chevron-down` : 'chevron-up'"
            class="mb-1 custom-icon float-right"
            @click="show = !show"
          ></b-icon>
        </div>
        <b-collapse id="unassigned" v-model="show">
          <draggable
            :list="featuredEndorsements"
            class="list-group"
            draggable=".item"
            group="a"
          >
            <div
              class="list-group-item item"
              v-for="element in featuredEndorsements"
              :key="element.endorsementId"
            >
              <b-row>
                <b-col cols="1">
                  <b-icon icon="arrows-move" aria-hidden="true" class="text-muted"></b-icon>
                </b-col>
                <b-col cols="4">{{
                  `${element.firstName} ${element.lastName}`
                }}</b-col>
                <b-col>
                   <span
                      class="key-color key-orange"
                      v-if="colorCheck(1, element.position)"
                    ></span>
                    <span
                      class="key-color key-red"
                      v-if="colorCheck(2, element.position)"
                    ></span>
                    <span
                      class="key-color key-green"
                      v-if="colorCheck(3, element.position)"
                    ></span>
                    <span
                      class="key-color key-yellow"
                      v-if="colorCheck(4, element.position)"
                    ></span>
                    <span
                      class="key-color key-purple"
                      v-if="colorCheck(5, element.position)"
                    ></span>
                </b-col>
                <b-col cols="4">
                  <b-row>
                    <b-col>
                      <span :class="
                          `featured-label float-right text-right text-${
                            element.featured ? 'black' : 'secondary'
                          }`
                        ">
                        Featured
                      </span>
                    </b-col>
                    <b-col cols="3">
                      <b-form-checkbox
                        switch
                        size="lg"
                        class="float-right"
                        v-model="element.featured"
                        :checked="element.featured"
                      ></b-form-checkbox
                    ></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </draggable>
        </b-collapse>
      </div>
      <hr />
      <div class="mt-4 footer-btn">
        <b-row>
          <b-col sm="6">
            <b-button
              variant="outline-secondary"
              align-self="left"
              size="lg"
              @click="$emit('prev')"
            >
              <b-icon icon="arrow-left"></b-icon>
              Back
            </b-button>
          </b-col>
          <b-col sm="6">
            <b-button variant="primary" size="lg" @click="updateGroup(true)"
              >Next
              <b-icon icon="arrow-right"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as CandidateService from "@/services/CandidateService2";
export default {
  components: {
    draggable
  },
  props: {
    featuredEndorsements: {
      type: Array,
      require: true
    },
    candidate: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      // groups: [],
      listGroups: [],
      groupItems: [],
      isEditing: false,
      newGroup: "",
      editingIndex: -1,
      show: true,
      groups: [...this.candidate.endorsementGroups]
    };
  },
  computed: {
    allGroups() {
      return this.groups;
    }
  },
  async mounted() {
    console.log("mounted");
    this.init();
  },
  watch: {
    listGroups() {
      this.updateGroup();
      console.log("watch list groups");
      return this.listGroups;
    },
    featuredEndorsements(){
      console.log("watch featured")
      
    },
    groupItems() {
      this.updateGroup();
    }
  },
  methods: {
    changeOrder(){
       this.saveGroups();
    },
    updateGroupOrder() {
      this.groups = this.groups.map((grp, idx) => {
        return { ...grp, orderID: idx + 1 };
      });
      // added updating of orderID
      this.listGroups = this.listGroups.map(grp => {
        grp.orderID = this.groups.filter(e => e.id == grp.groupId)[0].orderID;
        return grp;
      });
      // console.log(
      //   JSON.stringify(
      //     this.listGroups.sort((a, b) => {
      //       return a.orderID - b.orderID;
      //     })
      //   )
      // );
      //this.saveGroups();
      //console.log("update group order");  
      this.updateGroup();
    },
    updateGroup(next = false) {
     // console.log("update group");
      this.$emit("update", {
        listGroups: this.listGroups,
        groups: this.groups
      });
      if (next) {
        this.$emit("next");
      }
    },
    displayData() {
      // console.log(JSON.stringify(this.listGroups));
    },
    init() {
      this.listGroups = this.groups.map((group, idx) => {
        return {
          id: idx + 1,
          groupId: group.id,
          candidateId: this.candidate.id,
          members: this.featuredEndorsements.filter(function(item) {
            return item.endorsementGroupId == group.id;
          }),
          title: group.title
        };
      });
      this.groups = this.groups.map(e => ({
        ...e,
        visible: true
      }));
      //  console.log("init method")
    },
    addGroup() {
      if (this.editingIndex != -1) {
        this.groups[this.editingIndex].title = this.newGroup;
        this.isEditing = false;
        this.editingIndex = -1;
        this.newGroup = "";
        return;
      }
      if (this.newGroup.trim().length) {
        const groupID = this.groups.length;
        this.groups.push({
          id: groupID,
          orderID: groupID + 1,
          title: this.newGroup,
          visible: true
        });

        this.listGroups.push({
          id: this.listGroups.length,
          groupId: groupID,
          members: []
        });
      }
      this.isEditing = false;

      CandidateService.SaveEndorsementGroupAsync({
        groupTitle: this.newGroup,
        candidateId: this.candidate.id,
        order: this.groups.length + 1
      }).then(res => {
        setTimeout(() => {
          this.$store.commit("candidates/SET_CANDIDATE", res.data);
          this.newGroup = "";
        }, 500);
      });
    },
    editGroup(index) {
      this.newGroup = this.groups[index].title;
      this.editingIndex = index;
      this.isEditing = true;
    },
    deleteGroup(index) {
      const groupID = this.groups[index].id;
      let currentGroup = this.listGroups.filter(e => e.groupId == groupID)[0];
      this.groupItems = [...this.groupItems, ...currentGroup.members];
      this.groups.splice(index, 1);
      this.listGroups = this.listGroups.filter(e => e.groupId !== groupID);
      CandidateService.DeleteEndorsementGroupAsync({
        id: groupID,
        userId: this.candidate.userId
      }).then(res => {
        setTimeout(() => {
          this.$store.commit("candidates/SET_CANDIDATE", res.data);
          this.newGroup = "";
        }, 500);
      });
      // this.updateGroupOrder(); // added updating of orderID
    },
    saveGroups() {
      var data = this.listGroups.map((item, index) => {
        const candidateId = item.candidateId;
        const id = item.groupId;
        const order = item.orderID;
        const members = item.members;
        const title = item.title;
        return {
          id: id,
          candidateId: candidateId,
          title: title,
          order: order,
          members: members
        };
      });
      CandidateService.UpdateEndorsementGroupAsync(data).then(res => {
        setTimeout(() => {
          this.$store.commit("candidates/SET_CANDIDATE", res.data);
          this.newGroup = "";
        }, 100);
      });
    },
     colorCheck(id, positions) {
      let pos = positions.split(",");
      pos = pos.map((x) => {
        return parseInt(x);
      });
      var hasPosition = pos.some((x) => x === id);
      return hasPosition;
    },
  },
  created() {
   // console.log("created");
    this.init();
  }
};
</script>

<style lang="scss">
  .organize-tab {
    .featured-label {
      font-size: 12px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .btn-link {
      font-size: 14px;
    }

    .btn-lnk:focus {
      outline: none !important;
    }

    .list-group-item {
      margin-bottom: 15px;
    }

    .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-radius: 3px;
    }

    @media (max-width: 991px) {
      .list-group-item {
        .custom-switch {
          right: -15px;
        }
      }
    }

    @media (max-width: 575px) {
      .list-group-item {
        .custom-switch {
          right: 0;
        }
      }

      .btn-wrapper {
        display: block;
        margin-top: 10px;

        .btn-link {
          padding: 0;
        }
      }

      .footer-btn {
          .btn {
              width: 100%;
              margin-bottom: 20px;
          }
      }
    }
  }
</style>
